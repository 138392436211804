import { get404Props } from '@api';
import PageHeader from '@component/PageHeader';
import type { NextPage } from 'next';

const NotFound: NextPage = () => {
  return (
    <PageHeader
      {...{
        BackgroundShapeProps: {
          color: 'pinkLighter',
        },
        backgroundShapeFlip: true,
      }}></PageHeader>
  );
};
export const getStaticProps = get404Props;

export default NotFound;
